<template>
  <v-layout justify-center row>
    <v-dialog v-model="dialog" fullscreen hide-overlay>
      <template v-slot:activator="{ on, attr }">
        <v-list-item v-bind="attr" v-on="on">
          <v-row align="center" class="text-start" justify="center" no-gutters>
            <v-list-item-avatar class="my-auto">
              <v-icon x-large>mdi-store-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="`<b>${branch.brand_name}</b> #${branch.number}`"/>
              <v-list-item-subtitle v-text="branch.full_address"/>
            </v-list-item-content>
          </v-row>
        </v-list-item>
        <slot/>
      </template>
      <kurcc-site-branch-dialog-content :branch="branch" @close-dialog="dialog = false"/>
    </v-dialog>
  </v-layout>
</template>

<script>
import Branch from '@/modules/app/models/branch'

export default {
  name: 'KurccSiteBranchListItem',
  components: {
    KurccSiteBranchDialogContent: () => import('@/modules/more/components/KurccSiteBranchDialogContent')
  },
  props: {
    branch: {
      type: Branch,
      required: true
    }
  },
  data () {
    return {
      dialog: false
    }
  }
}
</script>
